import { useAuth0 } from '@auth0/auth0-react';
import { getKpiBookingAndAmountSummaries, getKpiSummary } from 'api/kpi';
import LoadingSkeleton from 'components/loadingSkeleton';
import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip, CartesianGrid, Bar, Label, LineChart } from 'recharts';

const StartPage = () => {

    const [bookingsAndAmounts, setBookingsAndAmounts] = useState([]);
    const [summary, setSummary] = useState();

    /* Get token from auth0 */
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const load = async () => {
            const token = await getAccessTokenSilently();
            console.log(token);
            const kpiBookingAndAmountsSummary = await getKpiBookingAndAmountSummaries(token);
            const kpiSummary = await getKpiSummary(token);
            setBookingsAndAmounts(kpiBookingAndAmountsSummary);
            setSummary(kpiSummary);
        }

        load();
    }, []);

    const customTickFormatter = (amount) => {
        return `${Math.ceil(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }

    const sekFormatter = new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK'
    });

    if (!bookingsAndAmounts || !summary) {
        return (
            <div className="mt-6 w-full">
                <LoadingSkeleton />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-y-6 mt-6 container items-center justify-center">
            <div className="flex md:flex-row flex-col justify-center gap-6 w-full">
                <div className="flex flex-col md:w-1/2 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Turnover this month</h2>
                    <span className="font-medium text-xl">{sekFormatter.format(summary.turnOverThisMonth)}</span>
                </div>
                <div className="flex flex-col md:w-1/2 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Turnover previous month</h2>
                    <span className="font-medium text-xl">{sekFormatter.format(summary.turnOverLastMonth)}</span>
                </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center gap-6 w-full">
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Parking spots</h2>
                    <span className="font-medium text-xl">{summary.numberOfParkingSpots}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Parking areas</h2>
                    <span className="font-medium text-xl">{summary.numberOfParkingAreas}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Permits</h2>
                    <span className="text-xl font-medium">{summary.numberOfPermits}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Users</h2>
                    <span className="font-medium text-xl">{summary.numberOfUsers}</span>
                </div>
            </div>
            <div className="flex min-h-[420px] w-full h-[300px] mt-6">
                <ResponsiveContainer>
                    <ComposedChart
                        width={300} height={200}
                        data={bookingsAndAmounts}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <XAxis dataKey="date" /><YAxis tickFormatter={customTickFormatter} unit='kr' width={80} />
                        <Tooltip />
                        <Legend />
                        <Bar name="Bookings" dataKey="bookingCount" barSize={20} fill="#33D085" />
                        <Bar name="Total amount" dataKey="totalAmount" barSize={20} fill="#2886FF" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StartPage;
