const BASE_URL = `${process.env.REACT_APP_API_ADMIN_BASE_URL}/Kpi`;

export const getKpiBookingAndAmountSummaries = async (token) => {
    const response = await fetch(BASE_URL + '/get-kpi-bookings-and-amount-summary', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }

  export const getKpiSummary = async (token) => {
    const response = await fetch(BASE_URL + '/get-kpi-summary', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })
  
    if (response.status === 200) {
      return response.json();
    }
  
    throw response;
  }
