import React from 'react';

const LoadingSkeleton = () => {
    return (
        <div className="flex gap-4 w-full max-h-24 items-center shadow-lg rounded-xl p-6 animate-pulse">
            <div className="flex h-6 w-6 bg-airpark-gray-300 rounded-full" />
            <div className="flex grow gap-4">
                <div className=" bg-gray-300 rounded-sm h-6 w-1/3" />
                <div className=" bg-gray-300 rounded-sm h-6 w-2/3" />
            </div>
        </div>
    );
};

export default LoadingSkeleton;