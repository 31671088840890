import React, { useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { ReactComponent as AirParkLogo } from 'assets/icons/ico_airpark_logo.svg'
import { useAuth0 } from '@auth0/auth0-react';

const DefaultLayout = () => {

    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (isLoading === false && isAuthenticated === false) {
            loginWithRedirect({});
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    return (
        <div className="flex flex-col p-6">
            <div className="flex justify-center items-center gap-x-6">
                <AirParkLogo className="md:w-[254px] w-[164px]" />
                <h1 className="text-3xl font-bold">AirPark KPI</h1>
            </div>

            <div className="flex justify-center items-center">
                <Outlet />
            </div>
        </div>
    );
};

export default DefaultLayout;