import React from 'react';
import StartPage from './pages/startPage/startPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/defaultLayout';

const App = () => {
  return (
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<StartPage />} />
        </Route>
      </Routes>);
}

export default App;